export default function initLanguageHandler() {
  const languageList = [...document.querySelectorAll('.js-language')];

  if (languageList.length === 0) return

  languageList.forEach(language => {
    const langBtn = language.querySelector('.js-language-btn');
    const langList = language.querySelector('.js-language-list');
    const langItems = langList.querySelectorAll('.js-language-item');
    const langIcon = langBtn.querySelector('.js-language-icon');

    const languageHandler = () => {
      langList.style.display = 'block';
    }

    langBtn.addEventListener('click', languageHandler);

    const handleClass = () => {
      const currentLang = langBtn.textContent.trim();

      langItems.forEach(item => {
        const langItem = item.children[0];
        const lang = langItem.textContent.trim();

        if (lang === currentLang) {
          langItem.classList.add('is-active');
        } else {
          langItem.classList.remove('is-active');
        }
      })
    }

    handleClass();

    langItems.forEach(item => {
      
      const langItem = item.children[0];
      const lang = langItem.textContent;

      const selectLanguage = () => {
        langBtn.textContent = lang;
        langBtn.append(langIcon);
        handleClass();
        langList.style.display = 'none';
      }

      langItem.addEventListener('click', selectLanguage);
    })

    const detectOutsideClick = (e) => {
      if (langList.contains(e.target) || (!langBtn.contains(e.target))){
        langList.style.display = 'none';
      }
    }

    window.addEventListener('click', detectOutsideClick);
    })
    // langBtn.addEventListener('click', languageHandler);



  


}