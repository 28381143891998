export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }


export const isValidInput = (input) => {
    if (input.value.length === 0 && input.hasAttribute('required')) {
      return false;
    }

    return true
  }

export const isCustomSelectValid = (select) => {
  const selectWrapper = select.closest('.custom-select');
  const selectInnerHTML = selectWrapper.querySelector('.select2-selection__rendered').innerHTML;

  if (selectInnerHTML instanceof HTMLElement) {
    return false;
  }
  return true;
}