import detectTouch from './lib/detectTouch'
import sayHello from './lib/sayHello'
import switchTabs from './lib/switchTabs'
import initAccordion from './scripts/accordion'
import initValid from './scripts/validate-personal-date'
import formValidate from './scripts/formValidate'
import checkedBtn from './scripts/checkedBtn'
import createInput from './scripts/createInput'
import fileLoader from './scripts/file-loader'
import subscribed from './scripts/personalSubscribed'

import showHideModal from './lib/showHideModal'
// import unsubscribe from './lib/unsubscribe';
import initSeclect2 from "./lib/selectTwo";
import initVehicleFromValidation from './scripts/validateVehicleForm';
import initLanguageHandler from './scripts/language';
import initEntryFormValidation from './scripts/entryFormValidation';
import initCalculateCostFormValidation from "./scripts/validateCalculateCostForm";
import changesSponsor from './scripts/changesSponsor'
import initFixedBtnObserver from './scripts/initObserver';

sayHello();
detectTouch();
initAccordion();
checkedBtn();
createInput();
switchTabs('.navigation-tabs__tab');
switchTabs('.cars-tabs__tab');
switchTabs('.js-info-button_01', true);
switchTabs('.js-info-button_02', true);

showHideModal('.js-modal-button');
initValid();
formValidate();

initLanguageHandler();
initEntryFormValidation('.js-entry-phone-form');
initEntryFormValidation('.js-entry-sms-form');
initCalculateCostFormValidation();
fileLoader('#inp-file');
subscribed();
changesSponsor();
// unsubscribe();
initSeclect2('.js-select_make');
initSeclect2('.js-select_model');
initVehicleFromValidation();
initFixedBtnObserver('.js-calculate-cost-form');
initFixedBtnObserver('.js-vehicle-form');
