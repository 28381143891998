import { isValidInput } from "../helpers/functions";

export default function initCalculateCostFormValidation() {
  const form = document.querySelector('.js-calculate-cost-form');

  if (!form) return;
  
  const input = form.querySelector('.js-input-field');
  const inputWrapper = input.closest('.form__input-wrap');

  input.addEventListener('keypress', () => {
    inputWrapper.classList.remove('is-not-valid', 'is-valid');
  })

  input.addEventListener('blur', () => {
    if (isValidInput(input)) {
      inputWrapper.classList.remove('is-not-valid');
      inputWrapper.classList.add('is-valid');
    } else {
      inputWrapper.classList.remove('is-valid');
      inputWrapper.classList.add('is-not-valid');
    }
  })

  form.addEventListener('submit', (event) => {
    const isInputsValid = isValidInput(input);

    const isFieldsValid = [isInputsValid].every(isValid => {
      return isValid === true;
    });

    if (!isFieldsValid) {
      event.preventDefault();
     }
   })
}