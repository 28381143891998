export default function formValidate(){
  const emailInput = document.getElementById("email-reg")
  const phoneInput = document.getElementById("tel")
  const dateInput = document.getElementById("date")

  const lastnameInput = document.getElementById("lastName")
  const firstnameInput = document.getElementById("firstName")
  const surnameInput = document.getElementById("surname")
  const addressInput = document.getElementById("address")

  const forEmail = document.getElementById("for-email-reg")
  const forPhone = document.getElementById("for-tel")
  const forDate = document.getElementById("for-date")

  const forLastname = document.getElementById("for-lastName")
  const forFirstname = document.getElementById("for-firstName")
  const forSurname = document.getElementById("for-surname")
  const forAddress = document.getElementById("for-address")

  const emailIsValid = /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{6,8})?$/
  const phoneIsValid = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){12,14}(\s*)?$/
  const dateIsValid = /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
  const lenght = /^[a-z\d-]{3,20}$/

  if(emailInput){
    emailInput.addEventListener("keyup", e => {
    if(emailIsValid.test(e.target.value)) {
      forEmail.className = "valid"
    }
    else {
      forEmail.className = "invalid"
    }
    })
  }

  if(phoneInput){
    phoneInput.addEventListener("keyup", e => {
      if(phoneIsValid.test(e.target.value)) {
        forPhone.className = "valid"
      }
      else {
        forPhone.className = "invalid"
      }
    })
  }

  if(dateInput){
    dateInput.addEventListener("keyup", e => {
      if(dateIsValid.test(e.target.value)) {
        forDate.className = "valid"
      }
      else {
        forDate.className = "invalid"
      }
    })
  }

  if(lastnameInput){
    lastnameInput.addEventListener("keyup", e => {
    if(lenght.test(e.target.value)) {
      forLastname.className = "valid"
    }
    else {
      forLastname.className = "invalid"
    }
    })
  }

  if(firstnameInput){
    firstnameInput.addEventListener("keyup", e => {
    if(lenght.test(e.target.value)) {
      forFirstname.className = "valid"
    }
    else {
      forFirstname.className = "invalid"
    }
    })
  }

  if(surnameInput){
    surnameInput.addEventListener("keyup", e => {
    if(lenght.test(e.target.value)) {
      forSurname.className = "valid"
    }
    else {
      forSurname.className = "invalid"
    }
    })
  }

  if(addressInput){
    addressInput.addEventListener("keyup", e => {
    if(lenght.test(e.target.value)) {
      forAddress.className = "valid"
    }
    else {
      forAddress.className = "invalid"
    }
    })
  }
}
