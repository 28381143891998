import { isValidInput, isCustomSelectValid } from "../helpers/functions";

export default function initVehicleFromValidation() {
  const form = document.querySelector('.js-vehicle-form');

  if (!form) return;

  const textInputs = [...form.querySelectorAll('.js-input-field')];
  const selectInputs = [...form.querySelectorAll('.js-select')];

  const fieldsStatus = {
    vehicleNumber: {
      valid: false
    },
    vehicleYear: {
      valid: false
    },
    vehicleMake: {
      valid: false
    },
    vehicleModel: {
      valid: false
    }
  };

  const changeBtnStatus = () => {
    const submitBtnList = form.querySelectorAll('.submit-button');

    submitBtnList.forEach(btn => {
      const allFieldsValid = Object.values(fieldsStatus).every(field => {
        return field.valid === true;
      })

      if (allFieldsValid) {
        btn.classList.remove('submit-button_disabled');
      }

      if (!allFieldsValid && !btn.classList.contains('submit-button_disabled')) {
        btn.classList.add('submit-button_disabled');
      }
    })
  }

  // Validating text inputs
  textInputs.forEach(input => {
    const inputValue = input.value.trim();
    
    if (inputValue) {
      const inputWrapper = input.closest('.form__input-wrap');
      fieldsStatus[input.dataset.type].valid = !!inputValue;
      inputWrapper.classList.add('is-valid');
    }

    input.addEventListener('keyup', () => {
      const inputWrapper = input.closest('.form__input-wrap');
      const isInputValid = isValidInput(input);
      fieldsStatus[input.dataset.type].valid = isInputValid;

      if (isInputValid) {
        inputWrapper.classList.remove('is-not-valid');
        inputWrapper.classList.add('is-valid');
      } else {
        inputWrapper.classList.remove('is-valid');
        inputWrapper.classList.add('is-not-valid');
      }

      changeBtnStatus();
    })
  })

  // Validating custom select inputs
  selectInputs.forEach(select => {
    $(select).on('select2:opening', () => {
      const selectWrapper = select.closest('.custom-select');
      selectWrapper.classList.add('is-open');
    });

    $(select).on('select2:closing', () => {
      const selectWrapper = select.closest('.custom-select');
      selectWrapper.classList.remove('is-open');
    });

    $(select).on('select2:select', () => {
      const isSelectValid = isCustomSelectValid(select);
      const selectWrapper = select.closest('.custom-select');
      fieldsStatus[select.dataset.type].valid = isSelectValid;

      if (isSelectValid) {
        selectWrapper.classList.remove('is-not-valid');
        selectWrapper.classList.add('is-valid');
      } else {
        selectWrapper.classList.remove('is-valid');
        selectWrapper.classList.add('is-not-valid');
      }
    });

    changeBtnStatus();
  })
}