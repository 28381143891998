export default function subscribed () {
  const sub = document.querySelectorAll('.gradient-btn')

  if(sub) {
    [...sub].forEach(element => {
      element.addEventListener('click', event => {
        event.preventDefault()
        const btnEl = event.target
        const perent = btnEl.closest('.options__data__info_tab')
        const inputWrap = perent.querySelector('.input-wrapp')
        
        const input = perent.querySelector('#sms')
        const inputBorder = perent.querySelector('.input-sms')

        const btn = perent.querySelector('.gradient-btn')

        const lenght = /^[a-z\d-]{3,20}$/

        inputWrap.style.display = 'block'

        if(input){
          input.addEventListener("keyup", e => {
          if(lenght.test(e.target.value)) {
            inputBorder.className = "valid"
          }
          else {
            inputBorder.className = "invalide"
          }
          })
        }

        btn.classList.add('second-click')

        const btnClick = perent.querySelector('.second-click')
        const form = perent.querySelector('.sms-form')
        const span = perent.querySelector('.unsubscribe-btn')

        if(btnClick) {
          btnClick.addEventListener('click', () => {
            form.style.display = 'none';
            btnClick.style.display = 'none';
            span.style.display = 'block';
          })
        }
    })
  })}
}