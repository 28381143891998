export default function initValid(){
  const emailInput = document.getElementById("email")
  const phoneInput = document.getElementById("phone")
  const forEmail = document.getElementById("forEmail")
  const forPhone = document.getElementById("forPhone")

  const emailIsValid = /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{6,8})?$/
  const phoneIsValid = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){12,14}(\s*)?$/

  if(emailInput){
    emailInput.addEventListener("keyup", e => {
    if(emailIsValid.test(e.target.value)) {
      forEmail.className = "green-gradient-border"
    }
    else {
      forEmail.className = "invalid"
    }
    })
  }


  if(phoneInput){
    phoneInput.addEventListener("keyup", e => {
      if(phoneIsValid.test(e.target.value)) {
        forPhone.className = "green-gradient-border"
      }
      else {
        forPhone.className = "invalid"
      }
    })
  }


}