export default function initEntryFormValidation(el) {
  const form = document.querySelector(el);

  if (!form) return;

  const formType = form.dataset.type;

  if (formType === 'phone') {
    const input = form.querySelector('input');
    const phoneRegex = /^\+[0-9]{3}\d{9}/;
    let inputValid = false;

    input.addEventListener('blur', () => {
      const inputValue = input.value;

      inputValid = phoneRegex.test(inputValue);
    })

    form.addEventListener('submit', (event) => {
      if (!inputValid) {
        event.preventDefault();
      }
    })
  }

  if (formType === 'sms') {
    const input = form.querySelector('input');
    let inputValid = false;

    input.addEventListener('blur', () => {
      const inputValue = input.value.trim();

      if (inputValue.length !== 0) {
        inputValid = true;
      }
    })

    form.addEventListener('submit', (event) => {
      if (!inputValid) {
        event.preventDefault();
      }
    })
  }
}