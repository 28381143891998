export default function changesSponsor() {
  const sponsor = document.querySelectorAll(".sponsors")

  const hightlight = (element) => {
    if(element) {
      [...sponsor].forEach(el => {
        el.classList.remove('active')
      })
    }
    element.classList.add('active')
  }

  document.addEventListener('click', event => {
    const btn = event.target.dataset.name
    const element = event.target

    if(btn !== 'one') {
      return
    }

    hightlight(element)

  })
}