const insuranceTabDetails = (tab, inx) => {
  const tabGlobalParent = tab.closest('.js-data-add');

  if (inx === 0) {
    if (tabGlobalParent.classList.contains('has-active')) tabGlobalParent.classList.remove('has-active');
  }
  if (inx > 0) {
    if (!tabGlobalParent.classList.contains('has-active')) tabGlobalParent.classList.add('has-active');
  }
}

const switchTabs = (tabSelector, fn = false) => {
  const tabs = [...document.querySelectorAll(tabSelector)]

  if (tabs.length === 0) return;

  tabs.forEach((tab, inx) => {
    tab.addEventListener('click', (e) => {
      const commonClassTabSelector = `.${e.currentTarget.dataset.tabCommonClass}`
      const activeTabIdSelector = `#${e.currentTarget.dataset.tabId}`
      const activeTab =  document.querySelector(activeTabIdSelector)

      const tabActiveClass = 'is-active'
      const tabContentActiveClass = 'tab_is-active'

      tabs.forEach((tab) => {
        if(tab.classList.contains(tabActiveClass)) tab.classList.remove(tabActiveClass)
      })
      document.querySelectorAll(commonClassTabSelector).forEach((element) => {
        if(element.classList.contains(tabContentActiveClass)) element.classList.remove(tabContentActiveClass)
      })
      e.currentTarget.classList.add(tabActiveClass)

      if (activeTab) activeTab.classList.add(tabContentActiveClass)

      if (fn) insuranceTabDetails(tab, inx);
    })
  })

  // default active
  if (!tabs[0].classList.contains('is-active')) tabs[0].classList.add('is-active');
}

export default switchTabs
