export default function fileLoader(selector, ) {
  const input = document.querySelector(selector)
  const btn = document.querySelector('#file-btn')
  const preview = document.createElement('div')

  preview.classList.add('preview')

  const options = ['.png', '.jpg', '.jpeg', '.gif']

  if(input && options && Array.isArray(options)) {
     input.setAttribute('accept', options.join(','))
  }

  const triggerInput = () => input.click();
  
  const changeHandler = event => {
    if (!event.target.files.length) {
      return
    }

    const files = Array.from(event.target.files)

    files.forEach(file => {
      if (!file.type.match('image')) {
        return
      }

      const reader = new FileReader()

      reader.onload = e => {
        input.insertAdjacentElement('afterend', preview)
        preview.insertAdjacentHTML('afterbegin', `
          <img src="${e.target.result}"/>
          <p>${file.name}</p>
        `)
        btn.style.display = 'none'
      }

      reader.readAsDataURL(file)
    })
  }

  if (btn) {
    btn.addEventListener('click', triggerInput)
  }

  if (input) {
    input.addEventListener('change', changeHandler)
  }
}