import { scrollHide, scrollShow } from './preventScroll';

const showHideModal = (buttonSelector) => {
  const button = [...document.querySelectorAll(buttonSelector)]
  const closeButton = [...document.querySelectorAll('.js-close-button')]

  button.forEach(btn => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      const modal = document.querySelector(`#${e.currentTarget.dataset.modalId}`)

      if (modal && !modal.classList.contains('is-visible')) {
        modal.classList.add('is-visible')
      }

      scrollHide();
    })
  })

  closeButton.forEach(btn => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      const modal = btn.closest('.modal');
      modal.classList.remove('is-visible');

      scrollShow();
    })
  })
}

export default showHideModal
