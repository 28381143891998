export default function createInput() {
  const submit = document.getElementById('toggleInput');
  const inputWrap = document.querySelector(".sms-input")
  
  if(submit) {
    submit.addEventListener('click', () => {

      inputWrap.classList.add('visible')
      submit.textContent = 'Підписати договір';
    });
  }
}
