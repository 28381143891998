export default function checkedBtn() {
  const checked = document.querySelectorAll('.checked');

  if (!checked) {
    return;
  }
  
  [...checked].forEach(btn => {
    btn.addEventListener('click', () => {
      btn.classList.toggle('check');
      
    });
  });

}

