const detectTouch = () => {
  function touch() {
    return 'ontouchstart' in window;
  }

  if (!touch()) {
    document.body.classList.add('no-touch')
  }
}

export default detectTouch;