export default function initFixedBtnObserver(el) {
  const form = document.querySelector(el);

  if (!form) return;
  
  // let submitBtn = '';
  // if (el === '.js-calculate-cost-form') {
  //   submitBtn = form.querySelector('.js-apply-kasko-btn');
  // }
  const submitBtn = form.querySelector('.js-apply-kasko-btn');
  const submitFixedBtnWrap = form.querySelector('.js-fixed-btn-wrap');

  const options = {
    threshold: 1.0
  }

  const callback = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        submitFixedBtnWrap.style.display = 'none';
      } else {
        submitFixedBtnWrap.style.display = 'block';
      }
    })
  };

  const observer = new IntersectionObserver(callback, options);
  observer.observe(submitBtn);

  // Disable/enabled observer on resizing (disabling for desktop devices)
  const handleObserver = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth > 768) {
      observer.disconnect();
      submitFixedBtnWrap.style.display = 'none';
    } else if (windowWidth <= 768) {
      observer.observe(submitBtn);
      submitFixedBtnWrap.style.display = 'block';
    }
  }

  window.addEventListener('resize', handleObserver);
  window.onload = handleObserver;
}