import select2 from 'select2';

export default function initSeclect2(el) {
  const form = document.querySelector('.js-vehicle-form');

  if (!form) return;

  $(el).select2({
    width: '100%',
    placeholder: '________'
  });

  $(document).on('select2:open', () => {
    document.querySelector('.select2-search__field').focus();
  })

  // Open vehicleMake custom select on page load
  if (el === '.js-select_make') {
    setTimeout(() => {
      $(el).select2('open');

      const element = document.querySelector(el);
      const selectWrapper = element.closest('.custom-select');

      selectWrapper.classList.add('is-open');
    }, 100)
  }
}