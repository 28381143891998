export default function initAccordion() {
  const btns = document.querySelectorAll('.js-accordion-btn');

  if (!btns) {
    return;
  }

  [...btns].forEach(btn => {
    btn.addEventListener('click', () => {
      btn.classList.toggle('is-open');
    });
  });
}